<template>
  <b-modal
    id="modal_customer"
    ref="modal_customer"
    size="lg"
    body-class="p-0"
    hide-footer
    centered
  >
    <template #modal-title> Müşteri Ara </template>
    <div>
      <div
        class="form-group m-2"
        v-if="customer_search.selected.customer_id == 0 && datasource.show_customer_create_panel == false "
      >
        <small class="text-sm text-uppercase font-semibold">Ara</small>
        <div class="row">
          <div class="col-sm-10">
            <vue-autosuggest
              ref="autocomplete"
              v-model="customer_search.query"
              :limit="10"
              :suggestions="customer_search.suggestions"
              :input-props="customer_search.inputProps"
              :section-configs="customer_search.sectionConfigs"
              :render-suggestion="renderSuggestion"
              @input="searchCustomer"
            />
          </div>
          <div class="col-sm-2">
            <button @click="datasource.show_customer_create_panel = true" class="btn btn-primary">Yeni</button>
          </div>
        </div>
      </div>
      <customer-create
        pagemode="modal"
        v-if="datasource.show_customer_create_panel"
        @modalcardclose="callBackModalCardClose()"
      ></customer-create>
      <div class="card" v-if="customer_search.selected.customer_id > 0">
        <div class="card-header">
          <div class="card-title">Müşteri Bilgileri</div>
        </div>
        <div class="card-body border-top">
          <b-row class="mt-1">
            <b-col lg="12" sm="12">
              <div v-if="customer_search.selected.customer_id > 0">
                <b-row class="mt-1">
                  <b-col sm="12" lg="12">
                    <div class="form-group">
                      <label class="font-medium text-uppercase">Müşteri</label>
                      <input
                        v-model="customer_search.selected.title"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="font-medium text-uppercase"
                        >Email Adresi</label
                      >
                      <input
                        v-model="customer_search.selected.email_primary"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div class="form-group">
                      <label class="font-medium text-uppercase">GSM</label>
                      <input
                        v-model="customer_search.selected.gsm_primary"
                        type="text"
                        class="form-control"
                        readonly
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-form-group v-if="datasource.showcancel">
                  <button
                    v-on:click="clearSelectedCustomer()"
                    class="btn btn-outline-danger text-danger"
                  >
                    İptal Et
                  </button>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import CustomerCreate from "../customer/CustomerCreate.vue";
import { VueAutosuggest } from "vue-autosuggest";
import utils from "@/service/utils";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  props: ["entitytype", "customer", "showcancel", "showmodal"],
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    CustomerCreate,
  },

  data() {
    return {
      datasource: {
        show_customer_create_panel: false,
        show_customer_create_button:false,
        showcancel: true,
      },
      customer_search: {
        datasuggest: [],
        query: "",
        suggestions: [],
        selected: {
          customer_id: 0,
          title: null,
          email_primary: null,
        },
        inputProps: {
          id: "search_customer",
          class: "form-control",
          type: "search",
          placeholder: "Müşteri no, gsm numarası ile ara",
        },
        sectionConfigs: {
          customers: {
            limit: 6,
            label: "",
            ulClass: "list-group list-none",
            liClass: "list-group-item",
            onSelected: (selected) => {
              if (this.entitytype == "serviceform") {
                this.sf.customer = selected.item;
                this.sf.sform.customer_id = selected.item.customer_id;
              }
              if (this.entitytype == "cargo") {
                this.cargo.customer = selected.item;
                this.cargo.cargo.customer_id = selected.item.customer_id;
              }
              this.customer_search.query = selected.item.title;
              this.customer_search.selected = selected.item;
              this.getCustomerDevicesItem();
              /*
              this.customer_search.selected = selected.item;
              this.sf.customer = selected.item;
              this.customer_search.query = selected.item.title;
              this.sf.sform.customer_id = selected.item.customer_id;
              */
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["getCustomerDevices"]),
    searchCustomer() {
      let keyword = this.customer_search.query;
      this.$store.dispatch("searchCustomer", { keyword, type: "customer" });
    },
    getCustomerDevicesItem() {
      if (this.customer_search.selected.customer_id == 0) return;
      this.getCustomerDevices({
        customer_id: this.customer_search.selected.customer_id,
      }).then((q) => {
        if (!q) return;
        if (q.success) {
          this.$emit("customerdevices", q.data);
        }
      });
    },
    clearSelectedCustomer() {
      this.customer_search.query = "";
      this.customer_search.suggestions = [];
      if (this.entitytype == "serviceform") {
        this.sf.sform.customer_id = 0;
        this.sf.customer = null;
      }
      if (this.entitytype == "cargo") {
        this.cargo.cargo.customer_id = 0;
        this.cargo.customer = null;
      }
      this.customer_search.selected.customer_id = 0;
    },
    renderSuggestion(suggestion) {
      const data = suggestion.item;
      return (
        <b-list-group>
          <b-list-group-item class="p-1">
            <div class="d-flex align-items-center">
              <div class="d-flex">
                <div>
                  <b-avatar src={data.profile_image} class="mr-50"></b-avatar>
                </div>
                <div>
                  <div>
                    <span>{data.title}</span>
                  </div>
                  <div>
                    <span>{data.gsm_primary}</span>
                    <span class="ml-1">{data.email_primary}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </b-list-group>
      );
    },
    callBackModalCardClose()
    {
      console.log('callBackModalCardClose');
      this.datasource.show_customer_create_panel = false;
      this.datasource.show_customer_create_button = true;

    }
  },
  created() {
    if (this.showcancel != null) {
      this.datasource.showcancel = this.showcancel == "true" ? true : false;
    }
    // if(!this.showcancel) this.showcancel = true;
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
  },
  watch: {
    customer: function (newVal) {
      if (newVal != null) {
        this.customer_search.selected = newVal;
      }
    },
    "sf.customer_search_list": function (newval, oldval) {
      this.datasource.show_customer_create_button = newval.length == 0;
      this.customer_search.suggestions = [];
      this.customer_search.suggestions.push({
        name: "customers",
        data: newval,
      });
    },
  },
};
</script>
 