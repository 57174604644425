<template>
  <b-modal id="modal_send_notification_sms"   @show="filterTemplate" centered hide-footer>
    <template #modal-title>Bildirim Gönder</template>
    <div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">Kişi</small>
            <input
              type="text"
              class="form-control"
              :value="_customer.title"
              readonly
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">GSM</small>
            <input
              type="text"
              class="form-control"
              :value="_customer.gsm_primary"
              readonly
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <small class="text-sm text-uppercase font-semibold">Mesaj</small>
        <v-select
          v-model="datasource.sms_definition"
          :searchable="true"
          :clearable="true"
          label="name"
          :options="datasource.sms_templates"
        />
      </div>
      <div class="form-group">
        <small class="text-sm text-uppercase font-semibold"
          >Gönderilecek Bildirim</small
        >
        <textarea
          :value="_sms_text"
          class="form-control"
          rows="5"
          readonly
        ></textarea>
      </div>
      <div class="form-group">
        <button
          v-if="_mode == 'send'"
          @click="sendNotification()"
          class="btn btn-sm btn-primary"
        >
          Gönder
        </button>
        <button
          @click="setSMSDefinition()"
          :disabled="datasource.sms_definition == null ? true : false"
          v-if="_mode == 'select'"
          class="btn btn-sm btn-primary"
        >
          Seç
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import utils from "@/service/utils";
import api from "@/service/api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
export default {
  components: {
    vSelect,
    flatPickr,
  },

  data() {
    return {
      datasource: {
        sms_definition: null,
        sms_templates: [],
        sms_text: "",
        sms_tempate_definitions:[]
      },
    };
  },
  methods: {
    setSMSDefinition() {
      if( this._entity_type == 'delivery' )
      {
          this.sf.delivery.notification_sms_text =  this.datasource.sms_definition.attr_text;
      }
      this.global.modal_send_sms_notification.sms_definition = this.datasource.sms_definition;
      this.$root.$emit("bv::toggle::modal", "modal_send_notification_sms");
    },
    getSMSTemplates() {

      api.action("getSMSTemplates").then((q) => 
      {
        if (q.success) 
        {
          this.datasource.sms_tempate_definitions = q.data;
        }
      });
    },
    filterTemplate()
    {   
        let items = this.datasource.sms_tempate_definitions.filter((qq) => qq.attr_1 == this._entity_type);
        this.datasource.sms_templates = items;
    },
    sendNotification() {
      if (this.datasource.sms_definition == null) {
        utils.notificationToast(
          "warning",
          "Bilgi",
          "Lütfen gönderilecek mesajı seçin."
        );
        return;
      }

      if (this.global.modal_send_sms_notification.customer == null) {
        utils.notificationToast(
          "warning",
          "Bilgi",
          "Lütfen mesaj gönderilecek kişiyi seçin."
        );
        return;
      }

      let postData = {
        customer_uniq:
          this.global.modal_send_sms_notification.customer.customer_uniq,
        entity: this.global.modal_send_sms_notification.entity,
        entity_uniq: this.global.modal_send_sms_notification.entityuniq,
        definition_id: this.datasource.sms_definition.definition_id,
      };

      api
        .action("sendSMSNotificationWithDefinition", { ...postData })
        .then((q) => {
          if (!q.success) {
            utils.notificationToast("warning", "Bilgi", q.message);
            return;
          }
          utils.notificationToast("success", "Bilgi", q.message);
        });
    },
  },
  computed: {
    ...mapState({
      global: (state) => state.global,
      sf:(state) => state.sf
    }),
    _sms_text() {
      let result = "";
      if (this.datasource.sms_definition != null)
        result = this.datasource.sms_definition.attr_text;
      return result;
    },
    _customer() {
      let result = { title: "", gsm_primary: "" };
      if (this.global.modal_send_sms_notification.customer != null) {
        result = this.global.modal_send_sms_notification.customer;
      }
      return result;
    },
    _mode() {
      return this.global.modal_send_sms_notification.mode;
    },
    _entity_type() {
      return this.global.modal_send_sms_notification.entity_type;
    },
  },
  created() {
    this.getSMSTemplates();
  },
};
</script>
 