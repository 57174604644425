<template>
  <div class="card" v-if="customer!=null">
    <div class="card-header">
      <div class="card-title">Müşteri Bilgileri</div>
      <div class="card-title">#{{ customer.customer_id }}</div>
    </div>
    <div class="card-body pt-2 border-top">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">Ad Soyad</small>
            <input
              :value="customer.title"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold"
              >Müşteri Kodu</small
            >
            <input
              :value="customer.customer_code"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold"
              >Müşteri No</small
            >
            <input
              :value="customer.customer_id"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">GSM</small>
            <input
              :value="customer.gsm_primary"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold">Email</small>
            <input
              :value="customer.email_primary"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <small class="text-sm text-uppercase font-semibold"
              >Doğum Tarihi</small
            >
            <input
              :value="_birthdate"
              readonly
              type="text"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import utils from "@/service/utils";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import _ from "lodash";
export default {
  props: ["customer"],
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
  },

  data() {
    return {
      datasource: {
        showcancel: true,
      },
    };
  },
  methods: {},
  created() {
  },
  computed: {
    _birthdate() {
      let result = "";
      if (utils.moment(this.customer.dateofbirth).isValid()) {
        result = utils.moment(this.customer.dateofbirth).format("DD.MM.YYYY");
      }
      return result;
    },
  },
};
</script>
 