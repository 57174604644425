<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Araç Bilgileri</div>
        <span> {{ datasource.car.plate_code }}</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Plaka Kodu</label>
              <input
                v-model="datasource.car.plate_code"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Aktif Mi?</label>
              <v-select
                v-model="datasource.car.is_active"
                :reduce="(curr) => curr.value"
                :searchable="false"
                :clearable="false"
                :options="global.yesno"
                label="label"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Marka</label>
              <input
                v-model="datasource.car.brand_name"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Model</label>
              <input
                v-model="datasource.car.model_name"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Yıl</label>
              <input
                v-model="datasource.car.car_year"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">KM</label>
              <input
                v-model="datasource.car.current_km"
                type="number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Sigorta Tarihi</label>
              <flat-pickr
                class="form-control"
                v-model="datasource.car.insurance_date"
                :config="{
                  enableTime: false,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="font-medium text-uppercase">Muayene Tarihi</label>
              <flat-pickr
                class="form-control"
                v-model="datasource.car.inspection_date"
                :config="{
                  enableTime: false,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <button @click="saveCar()" class="btn btn-primary">Kaydet</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import CardCustomerSearch from "@/views/common/CardCustomerSearch";
import CardCustomerInfo from "@/views/common/CardCustomerInfo";
import SendSmsNotification from "@/views/common/SendSmsNotification.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import utils from "@/service/utils";
import api from "@/service/api";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    vSelect,
    flatPickr,
    CardCustomerSearch,
    CardCustomerInfo,
    SendSmsNotification,
  },
  data() {
    return {
      datasource: {
        car: {
          plate_code: "",
          brand_name: "",
          model_name: "",
          car_year: "",
          current_km:0,
          insurance_date: "",
          inspection_date: "",
          is_active: "1",
          car_uniq: "",
        },
      },
    };
  },
  methods: {
    ...mapActions([]),

    saveCar() {
      if (this.datasource.car.plate_code.length <= 3) {
        utils.notificationToast("warning", "Bilgi", "Plaka kodu yazın.");
        return;
      }

      if (this.datasource.car.brand_name.length <= 2) {
        utils.notificationToast("warning", "Bilgi", "Marka yazın.");
        return;
      }

      if (this.datasource.car.model_name.length <= 2) {
        utils.notificationToast("warning", "Bilgi", "Model yazın.");
        return;
      }

      let postData = { ...this.datasource.car };
      api.action("saveCar", postData).then((q) => {
        if (q.success == false) {
          utils.notificationToast("warning", "Bilgi", q.message);
        } else {
          utils.notificationToast("success", "Bilgi", q.message);
          this.$router.replace({
            name: "carstracking-detailcar",
            query: { uniq: q.data },
          });
        }
      });
    },
    getCargoDetailItem() {
      let uniq = this.$route.query.uniq;
      if (!utils.isNullOrEmpty(uniq)) {
        this.getCargoDetail({ uniq: uniq });
      } else {
        this.$router.push("/not-found");
      }
    },
    moment(v) {
      return utils.moment(v);
    },
  },
  created() {
    this.datasource.car.car_year = moment().format("YYYY");
  },
  computed: {
    ...mapState({
      global: (state) => state.global,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>